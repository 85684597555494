
attribute float aScale;
uniform float uTime;

varying vec2 vUv;


void main(){
 
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);


    vec4 viewPosition = viewMatrix * modelPosition;
    gl_Position = projectionMatrix * viewPosition;

    vUv = uv;    
}
